import { useState } from 'react';
import { Box, Button, LinearProgress, TextField } from '@mui/material';
import useDuplicateMentions from '../../hooks/useDuplicateMentions';

type Summary = {
  totalMentions: number,
  totalDuplicates: number
}

export default function DuplicateChecker() {
  const [watchlistId, setWatchlistId] = useState<number>(-1);
  const [watchlistLink, setWatchlistLink] = useState<string>('');
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<Summary>({
    totalMentions: 0, totalDuplicates: 0
  });

  const {
    getDuplicates
  } = useDuplicateMentions();
  const isValid = watchlistId !== -1;

  const handleClick = async () => {
    setIsDone(false);
    setIsStarted(true);
    setWatchlistLink("https://watchlist.veritone.com/" + watchlistId + "/analytics");
    const duplicates = await getDuplicates(watchlistId);
    setIsDone(true);
    setIsStarted(false);
    setDuplicates({
      totalMentions: duplicates.totalMentions,
      totalDuplicates: duplicates.totalDuplicates,
    });
  };

  return (
    <Box>
      <h3>Duplicate Spots</h3>
      Duplicates are defined as 2 spots for the same advertiser appearing within 1 minute of each other. For any watchlist, you can check if this situation exists.
      <TextField
        type="number"
        margin="dense"
        id="watchlist_input"
        label="Watchlist ID"
        fullWidth
        variant="standard"
	onChange={(e) => setWatchlistId(parseInt(e.target.value, 10))}
      />
      <Button
        aria-label="find duplicates for watchlist"
        onClick={handleClick}
        disabled={!isValid}
      >Find Duplicates</Button>
      { isStarted ? <div><br /><LinearProgress /></div> : null }
      { isDone ? 
	<div>
	  <b>Total Mentions: </b> {duplicates.totalMentions}<br/>
	  <b>Total Duplicates: </b> {duplicates.totalDuplicates}<br/>
	<a target="_new" href={watchlistLink}>View Watchlist</a>
	</div>
	: null
      }
    </Box>
  );
}
