import MainHeader from "../../components/MainHeader";
import { Box, Card, CardContent, Typography } from '@mui/material';
import BulkOrgProvisioningForRadio from './BulkOrgProvisioningForRadio';
import BulkIngestionJobProvisioning from './BulkIngestionJobProvisioning';
import BulkSourceProvisioning from './BulkSourceProvisioning';
import BulkUserProvisioning from './BulkUserProvisioning';

export default function Aiware() {

  const ideas = [
    [`Mention Details`, `Print details of a single mention`],
    [`TDO Details`, `Print details of a single TDO`],
  ].map(idea => (
    <Card key={idea[0]}>
      <CardContent>
        <h3>{idea[0]}</h3>
        <p>{idea[1]}</p>
      </CardContent>
    </Card>
  ))

  return (
    <Box>
      <MainHeader title="aiWARE Data Structures" />
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>
        <BulkOrgProvisioningForRadio />
        <BulkSourceProvisioning />
        <BulkIngestionJobProvisioning />
        <BulkUserProvisioning />
      </Box>
      <h2>Roadmap</h2>
      <Typography>Coming soon...</Typography>
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>{ideas}</Box>
    </Box>
  );
}
