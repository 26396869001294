import Papa from 'papaparse';
import { ChangeEvent, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import client from '../apollo/client';

export enum STATUS {
  NONE,
  PROCESSING,
  SUCCESS,
  ERROR,
}

interface IWatchlistProgram {
  watchlistId: number,
  programId: number,
  status: STATUS,
};

const GET_WATCHLIST = gql`
query Watchlist($watchlistId: ID!) {
    watchlist(id: $watchlistId) {
	id
	details
    }
}`;

const UPDATE_WATCHLIST = gql`
mutation updateWatchlist($id: ID!, $details: JSONData) {
    updateWatchlist(input:{
        id: $id,
        details: $details
    }) {
        id
        details
    }
} `;

const useWatchlistProgramCsv = (shouldAdd: boolean) => {
  const [progress, setProgress] = useState<number>(0); // progress as a %
  const [wasSuccessful, setWasSuccessful] = useState<boolean>(true);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [canProcess, setCanProcess] = useState<boolean>(false);
  const [watchlistPrograms, setWatchlistPrograms] = useState<IWatchlistProgram[]>([])

  const [saveWatchlist, { loading: mloading, error: merror }] =
    useMutation(UPDATE_WATCHLIST);

  const uploadCsv = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files) {
      alert(`No uploaded file`);
      setCanProcess(false);
      setShowProgress(false);
      throw new Error(`No uploaded file`);
    }

    const file = e.target.files[0]

    setProgress(0);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        setWatchlistPrograms(results.data as IWatchlistProgram[])
      },
      error(err) {
        throw new Error(`Unable to parse CSV file ${err}`)
      }
    });
  }

  const saveWatchlistToServer = async (id: number, data: any) => {
    await saveWatchlist({
      variables: {
        id,
        details: data.watchlist.details
      }
    });
    if (mloading) {
      // should never get here
      throw new Error(`In loading state for watchlist ${id}`);
    }
    if (merror) {
      setWasSuccessful(false);
      throw new Error(`Error saving watchlist: ${id}\n${merror}`);
    }
  }

  const processWatchlistPrograms = async () => {
    console.log(watchlistPrograms)

    const promises = watchlistPrograms.map(entry => processWatchlist(entry.watchlistId, entry.programId))

    await Promise.all(promises)
  }

  // Remove single programId from watchlist
  const removeProgramIdFromList = async function(data: any, watchlistId: number, programId: number) {
    const ipl: number = data.watchlist.details.programIds.length;
    const pIdx: number = data.watchlist.details.programIds.indexOf(programId);
    if (pIdx > -1) {
      data.watchlist.details.programIds.splice(pIdx, 1); // remove that element
    }
    const fpl: number = data.watchlist.details.programIds.length;

    // never save less programIds than the original watchlist
    if (fpl < ipl) {
      console.log(`Updating ${watchlistId} from ${ipl} to ${fpl} programs (removing ${programId})`);
      await saveWatchlistToServer(watchlistId, data);
    } else if (fpl > ipl) {
      throw new Error(`ProgramIDs increased for ${watchlistId} when removing ${programId}`);
    } else {
      console.log(`No update for for ${watchlistId} when removing ${programId}`);
    }
  }
  // Add a single programId to a watchlist
  const addProgramIdToList = async function(data: any, watchlistId: number, programId: number) {
    const ipl: number = data.watchlist.details.programIds.length;
    const programIds: number[] = [...data.watchlist.details.programIds, programId];
    // ensure we only have unique programIds
    const uniqueProgramIds = Array.from(new Set(programIds));
    const fpl: number = uniqueProgramIds.length;

    // never save less programIds than the original watchlist
    if (fpl > ipl) {
      console.log(`Updating ${watchlistId} from ${ipl} to ${fpl} programs (adding ${programId})`);
      data.watchlist.details.programIds = uniqueProgramIds;
      await saveWatchlistToServer(watchlistId, data);
      return data;
    } else if (fpl < ipl) {
      throw new Error(`ProgramIDs decreased for ${watchlistId} when adding ${programId}`);
    } else {
      console.log(`No update for for ${watchlistId} when adding ${programId}`);
    }
  }

  // Update server state with new programIds added to the watchlist
  // Get watchlist, add programIds, save watchlist
  const processWatchlist =
      async function (watchlistId: number, programId: number) {
    console.log(`Processing watchlist with id of ${watchlistId}`)
    try {
      const {
        error,
        data,
      } = await client.query({
        query: GET_WATCHLIST,
        variables: {
          watchlistId
        },
      })

      if (error) {
        setWasSuccessful(false);
        throw new Error(`Error processing Watchlist: ${watchlistId} with program ${programId}\n${error}`);
      }
      // add ProgramIDs to existing list of programIDs
      if (!data || !data.watchlist
        || !data.watchlist.details
        || !data.watchlist.details.programIds) {
        setWasSuccessful(false);
        throw new Error('No programIds in watchlist: ' + watchlistId);
      } // will fail if watchlist isn't loaded

      if (shouldAdd) {
	await addProgramIdToList(data, watchlistId, programId);
      } else {
        await removeProgramIdFromList(data, watchlistId, programId);
      }

    } catch (error) {
      throw new Error(`Error while processing watchlist ${watchlistId} with program ${programId}: Error ${error}`)
    }
  }

  return {
    uploadCsv,
    progress,
    wasSuccessful,
    showProgress,
    canProcess,
    watchlistPrograms,
    processWatchlist,
    processWatchlistPrograms,
  };
};

export default useWatchlistProgramCsv;
