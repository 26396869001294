import { useState } from 'react';
import {
  CreateUserDocument, GetUserDocument
} from 'graphql/generated';
import { UserData } from 'types/UserData';
import { ProcessingState } from 'types/ProcessingState';
import { ProcessorHook } from 'types/ProcessorHook';
import client from 'apollo/client';
import useConsoleLogs from './useConsoleLogs';

const useProvisionUser = ():ProcessorHook => {
  const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.NotStarted);
  const [errorMessage, setErrorMessage] = useState<string>(``);

  const {
    formattedLogs,
    addLog,
  } = useConsoleLogs()

  const finishProcessing = (msg: string) => {
    if (msg === ``) {
      // successful
      setProcessingState(ProcessingState.Successful);
      addLog("Success!");
    } else {
      // Error
      addLog(msg);
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
      throw new Error(msg);
    }
  }

  const sendCreateUserRequest = async (csvRow: UserData) => {
    const {
      email,
      firstName,
      lastName,
      orgId,
      password,
      role,
      sendUserEmail,
      userName,
    } = csvRow

    addLog(`Creating new user`);
    debugger;

    const roleIds = role as string[];

    const {
      data,
    } = await client.mutate({
      mutation: CreateUserDocument,
      variables: {
        email,
        firstName,
        lastName,
	orgId,
        password,
	roleIds,
	sendUserEmail,
        username: userName,
      },
    })

    const msg: string = (!data || !data.createUser)
      ? `Error creating user for ${userName}`
      : '';
    finishProcessing(msg);
  }

  // See if the user exists, and if it does, return the username
  const sendGetUserRequest = async (csvRow: UserData) => {
    addLog(`Sending GetUserRequest`);

    const username = csvRow.userName;
    const orgIds = [csvRow.orgId];
    const {
      data,
    } = await client.query({
      query: GetUserDocument,
      variables: {
	orgIds,
	username
      },
    })

    if (!data || !data.users || !data.users.records) {
      finishProcessing(`Error searching for user ${username}`);
    }
    if (data.users!.records!.length < 1) {
      await sendCreateUserRequest(csvRow);
    } else {
      // TODO: Can add feature to update user details like role, name etc
      finishProcessing(`User ${username} already exists.`);
    }
  }

  const processor = async (data: UserData) => {
    addLog(`[${data.userName}] Starting User Provisioning`);
    try {
      setProcessingState(ProcessingState.Running);
      await sendGetUserRequest(data);
    } catch(err: unknown) {
      const msg = (err instanceof Error)
	? err.message
	: "Unknown error (ID: 888)";
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
    }
  }

  const printLogs = () => {
    console.log(formattedLogs);
  }

  return {
    processor,
    processingState,
    errorMessage,
    printLogs
  }
}

export default useProvisionUser;
