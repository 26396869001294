import { useState } from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { Dialog, DialogTitle, DialogContent,
	 DialogContentText, DialogActions} from '@mui/material';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import useWatchlistProgramCsv from '../../hooks/useWatchlistProgramCsv';
//import { DataGrid, GridColDef } from '@mui/x-data-grid';

//const columns: GridColDef[] = [
//  { field: 'id', headerName: 'Watchlist Id', width: 150 },
//  { field: 'programId', headerName: 'Program Id', width: 150 },
//  { field: 'status', headerName: 'Status', width: 150 },
//];

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function RemoveProgramFromWatchlist() {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const cancelUpload = () => {
    setShowDialog(false);
  }

  const continueUpload = () => {
    setShowDialog(false);
    processWatchlistPrograms();
  }

  const {
    uploadCsv: handleFileUpload,
    progress,
    showProgress,
    watchlistPrograms,
    processWatchlistPrograms,
  } = useWatchlistProgramCsv(false);

  return (
    <div>
      <h3>Remove ProgramID from Watchlist</h3>
      <p>Upload a CSV, containing the `WatchlistID` and `ProgramID`, and this tool will remove the programID from the watchlist. (Sample CSV here TODO)</p>
      <input
        type="file" name="csvFile"
        onChange={handleFileUpload}
      />
      <Button
        variant="contained"
        disabled={!watchlistPrograms.length}
	onClick={() => {setShowDialog(true)}}
      >
        Update Watchlists
      </Button>
      <Dialog
	open={showDialog}
      >
	<DialogTitle id="alert-dialog-title">
	  Remove programIds
	</DialogTitle>
	<DialogContent>
	  <DialogContentText id="alert-dialog-description">
	    You are about to remove programIDs from watchlists. Continue?
	  </DialogContentText>
	</DialogContent>
        <DialogActions>
	  <Button
	      variant="outlined"
	      color="error"
	      onClick={cancelUpload}
	  >
	  No, Cancel</Button>
          <Button onClick={continueUpload}>Yes, remove programIds</Button>
        </DialogActions>
      </Dialog>
      {showProgress ?
        <div><b>Processing Progress</b>:
          <LinearProgressWithLabel variant="determinate" value={progress} /></div>
        : null
      }
    </div>
  );
}
