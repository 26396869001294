import {
  CreateSourceCollaborator,
  SetSourcePermission,
} from 'graphql/generated';
import { RadioSourceData } from 'types/RadioSourceData';

const getCollaborators = (csvRow: RadioSourceData): CreateSourceCollaborator[] => {
  // Give the 'national' org view permissions
  const nationalACL: CreateSourceCollaborator = {
    organizationId: csvRow.parentOrg,
    permission: SetSourcePermission.Viewer
  };
  return [
    nationalACL
  ];
}

export default getCollaborators;
