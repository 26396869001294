// -- aiWARE clusters --

// attribute workflows
export const CLUSTER_ATTRIBUTE = "rt-a908d2a2-a98d-44c8-812b-0fee40d94e7d" as const;
// Default cluster
export const CLUSTER_PROD3 = "rt-1cdc1d6d-a500-467a-bc46-d3c5bf3d6901" as const;
// Ad-hoc job cluster
export const CLUSTER_PROD5 = "prd5-21xbyq0x-4h0s-o685-snas-oovhdai552v9" as const;

// ----------------------
