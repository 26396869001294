export type UserRole = string[];

const VERITONE_PERMISSIONS = {
  ATTRIBUTE_EDITOR   : "94e0ed56-0b23-4517-bcae-96d44689fd20",
  ATTRIBUTE_MANAGER  : "39f8ba2b-1e48-440c-9e8e-874865bfec08",
  ATTRIBUTE_ALL      : "6505c553-4f08-4c61-9862-d6d102946104",
  CMS_CS             : "6d982ee9-ff07-499f-a182-03457a6187f6",
  CMS_EDITOR         : "cf2ed945-176b-4dd9-943e-22fcb1cf684f",
  CMS_VIEWER         : "555033d1-508c-49c0-8127-66c2dc129828",
  COLLECTIONS_EDITOR : "e9c2c71a-80dd-4a35-af68-cbc256bb23a6",
  COLLECTIONS_VIEWER : "d1491325-cc45-4810-9d15-99cbf4761d66",
  DISCOVERY_EDITOR   : "3577dfc6-f441-41f9-8dab-ef9079530450", 
  DISCOVERY_VIEWER   : "5b195546-95ff-438b-81d7-c8505bf75bee"
}

export const ATTRIBUTE_USER_ROLE = [
  VERITONE_PERMISSIONS.ATTRIBUTE_EDITOR,
  VERITONE_PERMISSIONS.CMS_EDITOR,
] as UserRole;

export const ATTRIBUTE_MANAGER_ROLE = [
  VERITONE_PERMISSIONS.ATTRIBUTE_MANAGER,
  VERITONE_PERMISSIONS.CMS_EDITOR,
] as UserRole;

export const ATTRIBUTE_ALL_ACCESS_ROLE = [
  VERITONE_PERMISSIONS.ATTRIBUTE_ALL,
  VERITONE_PERMISSIONS.CMS_EDITOR,
] as UserRole;

export const ESSENTIALS_USER_ROLE = [
  VERITONE_PERMISSIONS.ATTRIBUTE_EDITOR,
  VERITONE_PERMISSIONS.CMS_EDITOR,
  VERITONE_PERMISSIONS.COLLECTIONS_EDITOR,
  VERITONE_PERMISSIONS.DISCOVERY_EDITOR,
] as UserRole;

export const ESSENTIALS_MANAGER_ROLE = [
  VERITONE_PERMISSIONS.ATTRIBUTE_MANAGER,
  VERITONE_PERMISSIONS.CMS_EDITOR,
  VERITONE_PERMISSIONS.COLLECTIONS_EDITOR,
  VERITONE_PERMISSIONS.DISCOVERY_EDITOR,
] as UserRole;

export const ESSENTIALS_ALL_ACCESS_ROLE = [
  VERITONE_PERMISSIONS.ATTRIBUTE_ALL,
  VERITONE_PERMISSIONS.CMS_EDITOR,
  VERITONE_PERMISSIONS.COLLECTIONS_EDITOR,
  VERITONE_PERMISSIONS.DISCOVERY_EDITOR,
] as UserRole;

export const BAD_ROLE = [] as UserRole;
