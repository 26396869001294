import { useState } from 'react';
import {
  CreateRadioJobScheduleDocument, GetRadioJobScheduleDocument, GetRadioSourceDocument, CreateRadioJobTemplateDocument, UpdateRadioJobScheduleDocument
} from 'graphql/generated';
import { RadioIngestData } from 'types/RadioIngestData';
import { ProcessingState } from 'types/ProcessingState';
import { ProcessorHook } from 'types/ProcessorHook';
import client from 'apollo/client';
import useConsoleLogs from './useConsoleLogs';
import { getScheduleParts } from 'utils/dateUtils';

const DAG_TEMPLATE_ID = "c4207812-3186-4547-91d0-56d37826f7c7" // V9 speechmatics

const useProvisionIngestionJob = ():ProcessorHook => {
  const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.NotStarted);
  const [errorMessage, setErrorMessage] = useState<string>(``);

  const {
    formattedLogs,
    addLog,
  } = useConsoleLogs()

  const finishProcessing = (msg: string) => {
    if (msg === ``) {
      // successful
      setProcessingState(ProcessingState.Successful);
      addLog("Success!");
    } else {
      // Error
      addLog(msg);
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
      throw new Error(msg);
    }
  }

  const sendCreateScheduleRequest = async (jobTemplateId: string, sourceTz: string, csvRow: RadioIngestData) => {
    const weeklyScheduleParts = getScheduleParts({
      startTime: csvRow.startTime,
      stopTime: csvRow.stopTime,
      scheduledDay: csvRow.scheduledDay,
      defaultFunc: finishProcessing,
      onInvalidTime: addLog,
      sourceTz,
    });
    const name = csvRow.scheduleName;
    const programFormat = csvRow.scheduleProgramFormat;
    const thumbnailUrl = csvRow.thumbnailUrl;
    const startDateTime = (new Date()).toISOString();// Start schedule ASAP

    const {
      data,
    } = await client.mutate({
      mutation: CreateRadioJobScheduleDocument,
      variables: {
        name,
	dagTemplateId: DAG_TEMPLATE_ID,
        jobTemplateId,
	programFormat,
        startDateTime,
        thumbnailUrl,
        weeklyScheduleParts,
      },
    })

    const msg = (!data?.createScheduledJob)
      ? `Error creating job schedule for ${csvRow.scheduleName}`
      : '';
    finishProcessing(msg);
  }

  const sendCreateJobTemplateRequest = async (sourceId: string, csvRow: RadioIngestData): Promise<string> => {

    const {
      data
    } = await client.mutate({
      mutation: CreateRadioJobTemplateDocument,
      variables: {
        clusterId: csvRow.clusterId,
        sourceId,
      },
    })

    if (!data || !data.createJobTemplate) {
      finishProcessing(`Error creating job template for ${csvRow.scheduleName}`);
    }

    return data!.createJobTemplate.id;
  }

  const sendUpdateScheduleRequest = async (jobTemplateId: string, sourceTz: string, csvRow: RadioIngestData, id: string) => {
    // update job schedule
    const weeklyScheduleParts = getScheduleParts({
      startTime: csvRow.startTime,
      stopTime: csvRow.stopTime,
      scheduledDay: csvRow.scheduledDay,
      defaultFunc: finishProcessing,
      onInvalidTime: addLog,
      sourceTz,
    });
    const name = csvRow.scheduleName;
    const programFormat = csvRow.scheduleProgramFormat;
    const thumbnailUrl = csvRow.thumbnailUrl;
    const jobTemplateIds = [jobTemplateId];
    const startDateTime = (new Date()).toISOString();// Start schedule ASAP

    const {
      data,
    } = await client.mutate({
      mutation: UpdateRadioJobScheduleDocument,
      variables: {
	dagTemplateId: DAG_TEMPLATE_ID,
	id,
        jobTemplateIds,
        name,
	programFormat,
        startDateTime,
        thumbnailUrl,
        weeklyScheduleParts,
      },
    })

    const msg = (!data?.updateScheduledJob)
      ? `Error updating job schedule for ${csvRow.scheduleName}`
      : '';
    finishProcessing(msg);
  }

  const sendGetScheduledJobRequest = async (sourceId: string, sourceTz: string, csvRow: RadioIngestData) => {
    const {
      data,
    } = await client.query({
      query: GetRadioJobScheduleDocument,
      fetchPolicy: 'network-only',
      variables: {
        name: csvRow.scheduleName,
      },
    })
    if (!data || !data.scheduledJobs || !data.scheduledJobs.records) {
      finishProcessing(`Error getting scheduled jobs for ${csvRow.scheduleName}`);
    }
    switch (data.scheduledJobs!.records!.length) {
      case 0:
        const jobTemplateId = await sendCreateJobTemplateRequest(sourceId, csvRow);
        await sendCreateScheduleRequest(jobTemplateId, sourceTz, csvRow);
	break;
      case 1:
	const record = data.scheduledJobs!.records![0];
	const id = record.id;
	// Yes, this will create a new job template, but if the customer wants to change
	// the clusterId, we need to create a new template
	// Plus, this is the way CMS handles updates, as per Jan 2023.
        const templateId = await sendCreateJobTemplateRequest(sourceId, csvRow); 
        await sendUpdateScheduleRequest(templateId, sourceTz, csvRow, id);
	break;
      default:
	const l = data.scheduledJobs!.records.length;
	finishProcessing(`${l} scheduled jobs found with the name: ${csvRow.scheduleName}. Use CMS to ensure there is only 1 before trying again`);
    }
  }

  // See if the source exists, and if it does, return the sourceId
  const sendGetSourceRequest = async (csvRow: RadioIngestData) => {
    addLog(`Sending GetSourceRequest`);

    const sourceName = csvRow.sourceName;
    const {
      data,
    } = await client.query({
      query: GetRadioSourceDocument,
      fetchPolicy: 'network-only',
      variables: {
        sourceName,
      },
    })

    if (!data || !data.sources) {
      finishProcessing(`Error searching for source ${sourceName}`);
    }
    if (data.sources.count < 1) {
      finishProcessing(`Source ${sourceName} does not exist. Create it first`);
    }

    const sourceId = data.sources.records[0].id;
    if (!data.sources.records[0].details ||
        !data.sources.records[0].details.liveTimezone) {
      finishProcessing(`Missing timezone info in ${sourceName}`);
    }
    const sourceTz = data.sources.records[0].details.liveTimezone;
    await sendGetScheduledJobRequest(sourceId, sourceTz, csvRow);
  }

  const processor = async (data: RadioIngestData) => {
    addLog(`[${data.scheduleName}] Starting IngestionJob Provisioning`);
    try {
      setProcessingState(ProcessingState.Running);
      await sendGetSourceRequest(data);
    } catch(err: unknown) {
      const msg = (err instanceof Error)
	? err.message
	: "Unknown error (ID: 777)";
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
    }
  }

  const printLogs = () => {
    console.log(formattedLogs);
  }

  return {
    processor,
    processingState,
    errorMessage,
    printLogs,
  }
}

export default useProvisionIngestionJob;
