import MainHeader from "../../components/MainHeader";
import { Box } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
//import StationCountPerOrg from 'components/Attribute/StationCountPerOrg';

export default function Aiware() {

  return (
    <Box>
      <MainHeader title="Sales Engineering" />
      <a target="_blank"
         rel="noreferrer"
	 href="http://se.veritone-ce.com">
        SE Homepage
        <LaunchIcon
	  sx={{ pt: 1 }}
	  fontSize="medium"
	/>
      </a>
    </Box>
  );
}
