import { ChangeEvent, useState, useRef } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import CsvDialog from 'components/Misc/CsvDialog';
import Information from 'components/Misc/Information';
import { StationData } from 'types/StationData';
import { CsvDialogActions } from 'types/CsvDialogActions';
import useWideOrbitStationIntegration from 'hooks/useWideOrbitStationIntegration';

const BulkIntegration = () => {

  const [showCsvDialog, setShowCsvDialog] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>("");

  const paragraphs = [
    'Upload a CSV containing Station information',
    'CSV requires the following columns:',
    '...',
    'station, band',
    '---',
  ]; 

  const parseRow = (data: any): StationData|null => {
    if (!data.orgId
	|| !data.station
	|| !data.band) {
      console.error(`Malformed CSV row`);
      return null;
    }

    return {
      orgId: data.orgId.trim(),
      station: data.station.trim(),
      band: data.band.trim()
    };
  }

  const dialogRef = useRef<CsvDialogActions>(null);

  const closeCsvDialog = () => {
    setShowCsvDialog(false);
  }

  const uploadCsv = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files) {
      alert(`No uploaded file`);
      throw new Error(`No uploaded file`);
    }

    if (!dialogRef || !dialogRef.current) {
      throw new Error("Dialog component broken");
    }
    // Call curl request to Wide orbit with API Key
    dialogRef.current.updateData({'apiKey': apiKey});
    dialogRef.current.processFile(e.target.files[0]);
    setShowCsvDialog(true);
    e.currentTarget.value = '';
  };

  return (
    <Box>
      <Stack direction="row">
	<h3>Integrate <em>Stations</em> in Bulk</h3>
	<Information
	  title="Link Stations to Wide Orbit"
	  paragraphs={paragraphs}
	/>
      </Stack>

      <p>Enter API key before uploading file</p>
      <TextField
        label="Wide Orbit API Key"
        variant="outlined"
	value={apiKey}
	onChange={(e:ChangeEvent<HTMLInputElement>) =>
	  setApiKey(e.target.value)}
      />
      <br />
      <input
        type="file" name="uploadFile"
        onChange={(e) => uploadCsv(e)}
      />
      <CsvDialog
        ref={dialogRef}
        title="Station Csv"
	open={showCsvDialog}
	handleClose={closeCsvDialog}
	rowParser={parseRow}
	rowProcessor={useWideOrbitStationIntegration}
	titleField='station'
      />
    </Box>
  );
}

export default BulkIntegration;
