import { useState } from 'react';
import { AllowDagInOrgDocument, AllowEnginesInOrgDocument,
	 AllowEngineCategoriesInOrgDocument
} from '../graphql/generated';
import { RadioDagData } from '../types/RadioDagData';
import { ProcessingState } from '../types/ProcessingState';
import { ProcessorHook } from 'types/ProcessorHook';
import client from '../apollo/client';
import useConsoleLogs from './useConsoleLogs';

const DAG_TEMPLATE_ID = "c4207812-3186-4547-91d0-56d37826f7c7" // V9 speechmatics

// See [1] for Attribute engine whitelisting debugging
// https://steel-ventures.atlassian.net/wiki/spaces/~5d3a743f3eb3ec0c892cdd2e/pages/2843115825/How+to+check+engine+in+org+and+add+to+whitelist
const ENGINE_CATEGORIES = [
  {
    id: "4b150c85-82d0-4a18-b7fb-63e4a58dfcce",
    name: "Pull: Get data from source URI",
  }, {
    id: "4be1a1b2-653d-4eaa-ba18-747a265305d8",
    name: "Ingestion",
  }, {
    id: "67cd4dd0-2f75-445d-a6f0-2f297d6cd182",
    name: "Transcription: Audio to text",
  }, {
    id: "925e8039-5246-4ced-9f2b-b456d0b57ea1",
    name: "Intracategory: Process results",
  }, {
    id: "c4207812-3186-4547-91d0-56d37826f7c7",
    name: "?",
  }, {
    id: "a70df3f6-84a7-4570-b8f4-daa122127e37",
    name: "Attribute V3f (Google data fetcher)",
  }, {
    id: "c5458876-43d2-41e8-a340-f734702df04a",
    name: "Attribute Schedule Report V3F",
  }, {
    id: "a70df3f6-84a7-4570-b8f4-daa122127e37",
    name: "Attribute CSV Playout Adapter",
  }
];

const ENGINES = [
    "54b3341f-7c2d-4991-9cf9-230e4b1de63b", // Attribute
    "a9707bb5-2ef3-4b7e-97f6-7cf3bc73bb23", // General CSV Playout Adapter V3
    "06d1ea74-507f-448f-9d17-f30aaea3b117", // Attribute Schedule Report V3F
    "c90bfb8a-bde3-4a1f-ae42-6c47f69812da", // Wide Orbit
];

const useProvisionDag = ():ProcessorHook => {
  const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.NotStarted);
  const [errorMessage, setErrorMessage] = useState<string>(``);

  const {
    formattedLogs,
    addLog,
  } = useConsoleLogs()

  const finishProcessing = (msg: string) => {
    if (msg === ``) {
      // successful
      setProcessingState(ProcessingState.Successful);
      addLog("Success!");
    } else {
      // Error
      addLog(msg);
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
      throw new Error(msg);
    }
  }

  const sendAllowDagRequest = async (csvRow: RadioDagData) => {
    const {
      orgId,
    } = csvRow

    addLog(`Allow Radio/TV English Transcription Dag for ${orgId}`);

    const {
      data,
    } = await client.mutate({
      mutation: AllowDagInOrgDocument,
      variables: {
        orgId,
	dagTemplateId: DAG_TEMPLATE_ID
      },
    })

    if (!data || !data.addDagTemplateToOrganization) {
      finishProcessing(`Error searching for source ${orgId}`);
    }
    await sendAllowEngineCategoriesInOrg(orgId);
    await sendAllowEnginesInOrg(orgId);
  }

  const sendAllowEngineCategoriesInOrg = async (orgId: string) => {

    addLog(`Allow Radio/TV English Transcription Engine Categories for ${orgId}`);

    const categoryIds: string[] = ENGINE_CATEGORIES.map(c => c.id);

    const {
      data,
    } = await client.mutate({
      mutation: AllowEngineCategoriesInOrgDocument,
      variables: {
	orgId,
	categoryIds,
      },
    })

    const msg: string = (!data || !data.deleteFromEngineBlacklist)
      ? `Error allowing engines for ${orgId}`
      : '';
    finishProcessing(msg);
  }

  const sendAllowEnginesInOrg = async (orgId: string) => {

    addLog(`Allow Attribute engines for ${orgId}`);

    const {
      data,
    } = await client.mutate({
      mutation: AllowEnginesInOrgDocument,
      variables: {
	organizationId: orgId,
	engineIds: ENGINES,
      },
    })

    const msg: string = (!data || !data.addToEngineWhitelist)
      ? `Error allowing engines for ${orgId}`
      : '';
    finishProcessing(msg);
  }

  const processor = async (data: RadioDagData) => {
    addLog(`[${data.orgId}] Starting Org Provisioning`);
    try {
      setProcessingState(ProcessingState.Running);
      await sendAllowDagRequest(data);
    } catch(err: unknown) {
      const msg = (err instanceof Error)
	? err.message
	: "Unknown error (ID: 777)";
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
    }
  }

  const printLogs = () => {
    console.log(formattedLogs);
  }

  return {
    processor,
    processingState,
    errorMessage,
    printLogs,
  }
}

export default useProvisionDag;
