import { useMutation } from '@apollo/client';
import { UserLoginDocument } from '../graphql/generated';

const useLogin = () => {
  const [onLogin, result] = useMutation(UserLoginDocument);

  return {
    onLogin,
    result,
  };
};

export default useLogin;
