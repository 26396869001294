import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import apolloClient from './apollo/client';
import { ThemeProvider } from '@veritone-ce/design-system';
import { CssBaseline } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// Removed strict mode because it duplicates requests locally

root.render(
    <ApolloProvider client={apolloClient} >
      <CssBaseline />
      <ThemeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
);
