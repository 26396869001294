import { ChangeEvent, useState, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import CsvDialog from 'components/Misc/CsvDialog';
import Information from 'components/Misc/Information';
import { UserData } from 'types/UserData';
import { UserRole, BAD_ROLE, ATTRIBUTE_ALL_ACCESS_ROLE, ATTRIBUTE_MANAGER_ROLE, ATTRIBUTE_USER_ROLE, ESSENTIALS_ALL_ACCESS_ROLE, ESSENTIALS_MANAGER_ROLE, ESSENTIALS_USER_ROLE } from 'types/UserRole';
import { CsvDialogActions } from 'types/CsvDialogActions';
import useProvisionUser from 'hooks/useProvisionUser';

const BulkUserProvisioning = () => {

  const [showCsvDialog, setShowCsvDialog] = useState<boolean>(false);

  const paragraphs = [
    'Upload a CSV containing User information',
    'NOTE: users get the CMS viewer perms, and WILL NOT receive an email',
    'CSV requires the following columns:',
    '...',
    'orgId,firstName,lastName,password,userName,role',
    '---',
    'You can add an optional `email` column, if the username and email are different',
    'Role can be one of `attribute user`, `attribute manager`, `essentials user`, `essentials manager`, `all access`'
  ]; 

  const getEmail = (data: any): string => {
    if (!data.email || data.email.trim().length < 10) {
      return data.userName.trim();
    }
    return data.email.trim();
  }

  // Coerce csv string into strongly typed role, or fail
  const getRole = (r: string): UserRole => {

    switch(r.trim().toLowerCase()) {
      case 'attribute user':
	return ATTRIBUTE_USER_ROLE;
      case 'attribute manager':
	return ATTRIBUTE_MANAGER_ROLE;
      case 'attribute all access':
	return ATTRIBUTE_ALL_ACCESS_ROLE;
      case 'essentials user':
	return ESSENTIALS_USER_ROLE;
      case 'essentials manager':
	return ESSENTIALS_MANAGER_ROLE;
      case 'essentials all access':
	return ESSENTIALS_ALL_ACCESS_ROLE;
      default:
	return BAD_ROLE;
    }
  }

  const parseRow = (data: any): UserData|null => {
    if (!data.orgId
	|| !data.firstName
	|| !data.lastName
	|| !data.password
	|| !data.role
	|| !data.userName) {
      console.error(`Malformed CSV row`);
      return null;
    }

    const role = getRole(data.role);
    if (role === BAD_ROLE) {
      console.error(`Undefined role of "${data.role}"`);
      return null; // TODO somehow add error message to UI
    }

    const sendEmail = false; // do not send email on user creation

    return {
      orgId: data.orgId.trim(),
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      password: data.password.trim(),
      userName: data.userName.trim(),
      sendUserEmail: sendEmail,
      email: getEmail(data),
      role: role
    };
  }

  const dialogRef = useRef<CsvDialogActions>(null);

  const closeCsvDialog = () => {
    setShowCsvDialog(false);
  }

  const uploadCsv = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files) {
      alert(`No uploaded file`);
      throw new Error(`No uploaded file`);
    }

    if (!dialogRef || !dialogRef.current) {
      throw new Error("Dialog component broken");
    }
    dialogRef.current.processFile(e.target.files[0]);
    setShowCsvDialog(true);
    e.currentTarget.value = '';
  };

  return (
    <Box>
      <Stack direction="row">
	<h3>Provision <em>Users</em> in Bulk</h3>
	<Information
	  title="Create Users"
	  paragraphs={paragraphs}
	/>
      </Stack>

      <input
        type="file" name="uploadFile"
        onChange={(e) => uploadCsv(e)}
      />
      <CsvDialog
        ref={dialogRef}
        title="User Csv Summary"
	open={showCsvDialog}
	handleClose={closeCsvDialog}
	rowParser={parseRow}
	rowProcessor={useProvisionUser}
	titleField='userName'
      />
    </Box>
  );
}

export default BulkUserProvisioning;
