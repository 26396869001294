import { CreateWeeklySchedulePart, DayOfWeek } from 'graphql/generated';
import moment from 'moment-timezone';

export type GetTimeProps = {
  time: string
  tz: string
  onInvalid: (message: string) => void
}

type Props = {
  day: string
  defaultFunc: (message: string) => void
}

type GetSchedulePartsProps = {
  startTime: string,
  stopTime: string,
  scheduledDay: string, // comma separated list of week days
  sourceTz: string,
  defaultFunc: (message: string) => void,
  onInvalidTime: (message: string) => void,
}

const getScheduledDay = ({
  day,
  defaultFunc,
}: Props): DayOfWeek => {
  switch (day.trim().toLowerCase()) {
    case 'monday':
      return DayOfWeek.Monday;
    case 'tuesday':
      return DayOfWeek.Tuesday;
    case 'wednesday':
      return DayOfWeek.Wednesday;
    case 'thursday':
      return DayOfWeek.Thursday;
    case 'friday':
      return DayOfWeek.Friday;
    case 'saturday':
      return DayOfWeek.Saturday;
    case 'sunday':
      return DayOfWeek.Sunday;
    default:
      defaultFunc(`Invalid day of the week for ${day}`);
      throw new Error("Invalid day of the week for schedule");
  }
}

// Generate the schedule information from the CSV row
export const getScheduleParts = ({
  startTime,
  stopTime,
  scheduledDay,
  defaultFunc,
  onInvalidTime,
  sourceTz,
}: GetSchedulePartsProps): CreateWeeklySchedulePart[] => {
  const weeklyScheduleParts: CreateWeeklySchedulePart[] = [];
  scheduledDay.split(',').forEach((sd) => {
    const wsp: CreateWeeklySchedulePart = {
      scheduledDay: getScheduledDay({
        day: sd,
        defaultFunc,
      }),
      startTime: getTime({
        time: startTime,
        tz: sourceTz,
        onInvalid: onInvalidTime,
      }),
      stopTime: getTime({
        time: stopTime,
        tz: sourceTz,
        onInvalid: onInvalidTime,
      }),
    };
    weeklyScheduleParts.push(wsp);
  });
  return weeklyScheduleParts;
}

// convert time from string to Time object
const getTime = function ({
  time,
  tz,
  onInvalid,
}: GetTimeProps) {
  const tzReg = new RegExp(/.*[-+]\d{2}:00/, 'g'); // expect time in HH:00 format
  if (tzReg.test(time)) {
    onInvalid(`Invalid time: ${time}`);
  }
  let offset = moment().tz(tz).format('Z');

  // conform time to HH:mm format
  // TODO: Improve this crappy implementation at your leisure
  // naïvely, add missing trailing zero
  if (time.split(":")[0].length < 2) {
    time = "0" + time;
  }
  // remove trailing am/pm if exists
  const t = time.slice(-2);
  if (t === 'am' || t === 'pm') {
    time = time.slice(0, -2);
  }

  return time + offset;
}
