import { FC } from 'react';
import { Box, Typography } from '@mui/material';

const Dashboard: FC = () => {

    return (
	<Box>
	    <Typography>Welcome to the Forge, the place where useful operational tools are forged for daily use. To get started select an app from the menu on the left. </Typography>
	</Box >
    );
}

export default Dashboard;
