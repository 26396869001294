import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom';
const drawerWidth = 240;

const links = [
  {
    text: `Home`,
    link: `/`,
  },
  {
    text: `aiWARE data structures`,
    link: `/aiware`,
  },
  {
    text: `Attribute`,
    link: `/attribute`,
  },
  {
    text: `Discovery`,
    link: `/discovery`
  },
  {
    text: `DMH`,
    link: `/dmh`
  },
  {
    text: `Navigate`,
    link: `/navigate`
  },
  {
    text: `Sales Engineering`,
    link: `/se`
  },
  {
    text: `SportX`,
    link: `/sportx`
  },
  {
    text: `Voice`,
    link: `/voice`
  },
  {
    text: `Wide Orbit`,
    link: `/wideorbit`
  },
]

function AppDrawer() {
  const navigate = useNavigate();

  const handleMenuItemClick = (link: string) => {
    navigate(link);
  }

  const listItems = links.map(({
    text,
    link,
  }) => (
    <ListItem key={text} disablePadding>
      <ListItemButton onClick={() => handleMenuItemClick(link)}>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  ))

  return (
    <Drawer
      
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>{listItems}</List>
      </Box>
    </Drawer>
  )
}

export default AppDrawer
