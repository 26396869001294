import { useState } from 'react';
import {
  CreateWideOrbitStationDocument, CreateWideOrbitJobTemplateDocument, CreateWideOrbitJobScheduleDocument, GetSourcesByNameDocument } from 'graphql/generated';
import { StationData } from 'types/StationData';
import { ProcessingState } from 'types/ProcessingState';
import { ProcessorHook } from 'types/ProcessorHook';
import client from 'apollo/client';
import useConsoleLogs from './useConsoleLogs';
import { getScheduleParts } from 'utils/dateUtils';
import { CLUSTER_PROD3 } from 'constants/cluster';

const WO_URL = "https://dapi.wocentral.com/wodatawebapi/api/Stations/GetAllowedTrafficStations";
const WO_PARTNER_ID = "VERIT_00MKoL";
const WO_API_KEY = "4Ms1c0";
const WO_SDO_SCHEMA = "0d68c249-6ca5-4b33-8f95-122a1c277d79";

type SdoInput = {
  schemaId: string,
  data: {
    stationCallLetters: string
    trafficStationInt: number
  }
};

const useWideOrbitStationIntegration = ():ProcessorHook => {
  const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.NotStarted);
  const [errorMessage, setErrorMessage] = useState<string>(``);

  const {
    formattedLogs,
    addLog,
  } = useConsoleLogs()

  const finishProcessing = (msg: string) => {
    if (msg === ``) {
      // successful
      setProcessingState(ProcessingState.Successful);
      addLog("Success!");
    } else {
      // Error
      addLog(msg);
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
      throw new Error(msg);
    }
  }

  const getWideOrbitData = async (agreementKey: string) => {
    const options = {
      headers: {
	"api-key": WO_API_KEY,
	"partner-id": WO_PARTNER_ID,
	"agreement-key": agreementKey
      }
    };

    return fetch(WO_URL, options)
      .then((response) => response.json())
      .then((data) => {
	return data;
      });
  }

  const getSdoData = (woData: any[], sd: StationData): SdoInput|null => {
    // search through the woData to find the station

    const callLetters = sd.station + '-' + sd.band;
    const woStation = woData.filter(function(wd) {
      return wd.StationCallLetters && wd.StationCallLetters.startsWith(callLetters)
    });

    if (woStation.length === 0) {
      // No match found
      finishProcessing(`${callLetters} not found in WideOrbit for this apiKey`);
    }

    // TODO. Check if more than 1 match is found, though this is unlikely
    const ws = woStation[0];

    return {
      schemaId: WO_SDO_SCHEMA,
      data: {
	stationCallLetters: callLetters,
	trafficStationInt: ws.StationInt
      }
    }
  }

  const sendCreateSDORequest = async(station: string, input: any) => {
    // NOTE: if one already exists, a new one will be created. There seems to
    // be no way to check for an existing record, and also seems to be no
    // adverse affects to having duplicate records
    // See https://steel-ventures.atlassian.net/browse/FRG-6
    const {
      data,
    } = await client.mutate({
      mutation: CreateWideOrbitStationDocument,
      variables: {
	input: input!
      },
    })

    if (!data || !data.createStructuredData || !data.createStructuredData.id) {
      finishProcessing(`Error creating Wide Orbit station ${station}`);
    }
  }

  const sendGetSourceRequest = async (station: string, band: string): Promise<string> => {
    const {
      data
    } = await client.query({
      query: GetSourcesByNameDocument,
      variables: {
        name: station,
      },
    })

    if (!data || !data.sources || !data.sources.records ) {
      finishProcessing(`Error getting sources for ${station}`);
    }

    // return the first one that matches station and band (there should just be 1)
    let sourceId = "0";
    data.sources.records.forEach((r) => {
      if (r.details.stationBand && r.details.stationCallSign
	  && r.details.stationCallSign.toUpperCase() === station.toUpperCase()
	  && r.details.stationBand.toUpperCase() === band.toUpperCase()) {
	sourceId = r.id;
      }
    })

    if (sourceId === "0") {
      finishProcessing(`Error couldn't find ${station}-${band} source. Does it exist?`);
    }
    return sourceId;
  }

  const sendCreateJobTemplateRequest = async (station: string, sourceId: string, apiKey: string, orgId: string): Promise<string> => {

    const clusterId = CLUSTER_PROD3;

    const {
      data
    } = await client.mutate({
      mutation: CreateWideOrbitJobTemplateDocument,
      variables: {
        clusterId,
	orgId,
        sourceId,
	apiKey
      },
    })

    if (!data || !data.createJobTemplate) {
      finishProcessing(`Error creating job template for ${station}`);
    }

    return data!.createJobTemplate.id;
  }

  // Get a random hour, between 11pm and 6am, and return as HH:mm string
  const getRandomTimeRange = ():[string,string] => {
    const maxTime = 8; // 11,12,1,2,3,4,5,6 are valid hours
    // random minute and second because why not
    const startMinSec = ":07";
    const stopMinSec = ":37";
    let t = Math.floor(Math.random() * maxTime);
    if (t === 0) {
      t = 12;
    } else if (t === 7) {
      t = 11;
    }
    return [t+startMinSec, t+stopMinSec];
  }

  const sendCreateIngestionJobRequest = async (station: string, band: string, jobTemplateId: string) => {
    const name = `Wide Orbit Traffic Pull - ${station}-${band}`;
    // job schedule is activated right now
    const startDateTime = (new Date()).toISOString();
    // schedule pull for every day of the week
    const scheduledDay = "monday,tuesday,wednesday,thursday,friday,saturday,sunday";
    // Pull file every day between 11pm and 6am PST
    const [startTime, stopTime] = getRandomTimeRange();
    // const use PST timezone
    const sourceTz = "America/Los_Angeles";

    const weeklyScheduleParts = getScheduleParts({
      startTime,
      stopTime,
      scheduledDay,
      defaultFunc: finishProcessing,
      onInvalidTime: addLog,
      sourceTz,
    });
    const {
      data
    } = await client.mutate({
      mutation: CreateWideOrbitJobScheduleDocument,
      variables: {
	name,
	jobTemplateId,
        startDateTime,
        weeklyScheduleParts	
      },
    })

    if (!data || !data.createScheduledJob) {
      finishProcessing(`Error creating scheduled job for ${station}`);
    }
  }

  // See if the user exists, and if it does, return the username
  const sendIntegrationRequest = async (csvRow: StationData, agreementKey: string) => {
    // This logic is based on these instructions:
    // https://steel-ventures.atlassian.net/wiki/spaces/OLD/pages/1104347867/WideOrbit+Traffic+Adapter
    addLog(`Sending IntegrateWideOrbit request`);

    // Get the stations from Wide Orbit to get the stations
    // TODO put this in a singleton so WO doesn't get called per row
    const woData = await getWideOrbitData(agreementKey);

    // Find relevant station in WO list 
    const input = getSdoData(woData, csvRow);

    await sendCreateSDORequest(woData.station, input);

    // Now create scheduled job for that station
    const sourceId = await sendGetSourceRequest(csvRow.station, csvRow.band);
    const templateId = await sendCreateJobTemplateRequest(
      csvRow.station, sourceId, agreementKey, csvRow.orgId);

    await sendCreateIngestionJobRequest(
csvRow.station, csvRow.band, templateId);

    finishProcessing('');
  }

  const processor = async (data: StationData, userData: any) => {
    addLog(`[${data.station}] Starting Wide Orbit Integration`);
    if (!userData.apiKey) {
      setErrorMessage(`No API key defined`);
      setProcessingState(ProcessingState.Error);
      return;
    }

    try {
      setProcessingState(ProcessingState.Running);
      await sendIntegrationRequest(data, userData.apiKey);
    } catch(err: unknown) {
      const msg = (err instanceof Error)
	? err.message
	: "Unknown error (ID: 888)";
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
    }
  }

  const printLogs = () => {
    console.log(formattedLogs);
  }

  return {
    processor,
    processingState,
    errorMessage,
    printLogs
  }
}

export default useWideOrbitStationIntegration;
