import { Routes, Route } from "react-router-dom";
import { useReactiveVar } from '@apollo/client';
import { environmentVar, isAuthenticatedVar, userVar } from './apollo/cache';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';

import Dashboard from './components/Dashboard';
import Aiware from './components/Aiware';
import Attribute from './components/Attribute';
import Dmh from './components/Dmh';
import Discovery from './components/Discovery';
import Navigate from './components/Navigate';
import Sportx from './components/Sportx';
import Voice from './components/Voice';
import WideOrbit from './components/WideOrbit';
import SalesEngineering from './components/SalesEngineering';
import LoginDialog from './components/LoginDialog';
import { Box } from "@mui/material";
import AppDrawer from "./components/AppDrawer";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import getEnvName from "./utils/getEnvName";
import logout from "./utils/logout";
import { red } from "@mui/material/colors";
import { Environments } from "./utils/envs";

function App() {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar)

  if (!isAuthenticated) {
    return (
      <LoginDialog />
    )
  }

  function isProd() {
    return environmentVar() === Environments.US_PRODUCTION ||
           environmentVar() === Environments.UK_PRODUCTION;
  }

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: isProd() ? red[400] : `primary`,
        }}
      >
        <Toolbar>
          <Typography
            variant="h6" noWrap component="div"
            sx={{ flexGrow: 1 }}
          >
	    Forge
          </Typography>
          <Box sx={{
            display: `flex`,
            gap: 2,
            alignItems: `center`,
          }}>
            <Typography align="right">
              Signed as <b>{userVar()?.email}</b><br />
              On <b>{getEnvName()}</b>
            </Typography>
            <IconButton onClick={logout}>
              <ExitToAppIcon htmlColor="white" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <AppDrawer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/aiWARE" element={<Aiware />} />
          <Route path="/attribute" element={<Attribute />} />
          <Route path="/discovery" element={<Discovery />} />
          <Route path="/dmh" element={<Dmh />} />
          <Route path="/navigate" element={<Navigate />} />
          <Route path="/se" element={<SalesEngineering/>} />
          <Route path="/sportx" element={<Sportx />} />
          <Route path="/voice" element={<Voice />} />
          <Route path="/wideorbit" element={<WideOrbit />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
