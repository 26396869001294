import { ApolloClient, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import getVeritoneEnv from '../utils/getVeritoneEnv';
import { authTokenVar, cache, environmentVar } from './cache';

// https://www.apollographql.com/docs/react/networking/authentication/
const httpLink = createHttpLink({
  uri: `https://api.stage.us-1.veritone.com/v3/graphql`,
});

const dynamicLink = setContext((_, { headers }) => {
  const token = authTokenVar();
  const env = environmentVar();

  return {
    uri: getVeritoneEnv(env),
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ``,
    },
  };
});

const client = new ApolloClient({
  link: from([dynamicLink, httpLink]),
  cache,
  connectToDevTools: true,
});

export default client;
