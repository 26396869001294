import { useState } from 'react';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

type props = {
  title: string,
  paragraphs: string[],
}

const Information = ({
  title,
  paragraphs,
}: props) => {

  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);

  const toggleShowInfoDialog = () => {
    setShowInfoDialog(!showInfoDialog);
  }

  const content = paragraphs.map((p, i) => (
    <DialogContentText key={i}>
      {p}
    </DialogContentText>
  ));

  return (
    <div>
      <IconButton
	sx={{mt: 1}}
	onClick={toggleShowInfoDialog}
	aria-label="show-information"
      >
        <InfoIcon color="primary"/>
      </IconButton>
      <Dialog
        open={showInfoDialog}
	onClose={() => setShowInfoDialog(false)}
        aria-labelledby="csv-processing"
        aria-describedby="csv-processing-details"
      >
        <DialogTitle id="Csv-processing-dialog-title">
	  {title}
        </DialogTitle>
        <DialogContent>
	  {content}
        </DialogContent>
        <DialogActions>
	  <Button
	    onClick={() => setShowInfoDialog(false)}
	    autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Information;
