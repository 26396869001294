import { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
//import StackSelector from '../Misc/StackSelector';
// TODO: Use Button from @veritone-ce@design-system once the design-system tests are fixed
//import Button from '@veritone-ce/design-system';
import { useReactiveVar } from '@apollo/client';
import { authTokenVar, environmentVar, isAuthenticatedVar, userVar } from '../../apollo/cache';
import useLogin from '../../hooks/useLogin';
import { Environments } from '../../utils/envs';

const SESSION_EMAIL="v_email";
const SESSION_PW="v_pw";

const LoginDialog = () => {
  const initialEmailValue: string =
    sessionStorage.getItem(SESSION_EMAIL) || ``;
  const [email, setEmail] = useState<string>(initialEmailValue)

  const initialPwValue: string =
    sessionStorage.getItem(SESSION_PW) || ``;
  const [password, setPassword] = useState<string>(initialPwValue)
  const isAuthenticated = useReactiveVar(isAuthenticatedVar)
  const env = useReactiveVar(environmentVar)
  const {
    onLogin,
  } = useLogin()

  const handleClick = async () => {
    sessionStorage.setItem(SESSION_EMAIL, email);
    sessionStorage.setItem(SESSION_PW, password);

    const res = await onLogin({
      variables: {
        input: {
          userName: email,
          password,
        }
      }
    });

    if (!res.data) {
      throw new Error(`Data is undefined!`);
    }

    if (!res.data.userLogin) {
      throw new Error(`User Login is undefined!`);
    }

    if (!res.data.userLogin.token) {
      throw new Error(`Token Login is undefined!`);
    }

    if (res.data.userLogin.user) {
      userVar({
        ...res.data.userLogin.user,
        email: res.data.userLogin.user.email ? res.data.userLogin.user.email : ``,
      });
    }

    isAuthenticatedVar(true);
    authTokenVar(res.data.userLogin.token);
  }

  const isValid = email && password;
  return (
    <Dialog
      open={!isAuthenticated}
    >
      <DialogTitle>Sign in</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter email and password to sign in.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email_input"
          label="Email Address"
          type="email"
	  defaultValue={email}
          fullWidth
          variant="standard"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="password_input"
          label="Password"
          type="password"
	  defaultValue={password}
          fullWidth
          variant="standard"
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            mb: 2,
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="env-select-label">Environment</InputLabel>
          <Select
            labelId="env-select-label"
            id="env-select"
            label={`Environment`}
            variant={`standard`}
            value={env}
            onChange={(e) => environmentVar(e.target.value as Environments)}
          >
            <MenuItem value={Environments.DEV}>Dev</MenuItem>
            <MenuItem value={Environments.US_STAGE}>US Stage</MenuItem>
            <MenuItem value={Environments.UK_STAGE}>UK Stage</MenuItem>
            <MenuItem value={Environments.US_PRODUCTION}>US Prod</MenuItem>
            <MenuItem value={Environments.UK_PRODUCTION}>UK Prod</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClick}
          disabled={!isValid}
        >Sign In</Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoginDialog
