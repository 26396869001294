// This component represents a single CsvRow. It contains a title, and will
// kick off the processing of the data represented by the CsvRow. This component
// shows processing status, and gives the user the ability to see errors (if any).
import { ReactNode } from 'react';
import { Button, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DangerousIcon from "@mui/icons-material/Dangerous";
import ScheduleIcon from "@mui/icons-material/Schedule";
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { ProcessingState } from 'types/ProcessingState';
import { ProcessorHook } from 'types/ProcessorHook';

type props = {
  title: string,
  data: any,
  userData: any,
  hook: () => ProcessorHook
}

const CsvRow = ({
  title,
  data,
  userData,
  hook,
}: props) => {
  const activeHook = hook();

  if (activeHook.processingState === ProcessingState.NotStarted) {
    activeHook.processor(data, userData);
  } else if (activeHook.processingState === ProcessingState.Error) {
    activeHook.printLogs();
  }

  const getIcon = (): ReactNode => {
    if (activeHook.processingState === ProcessingState.NotStarted) {
      return <ScheduleIcon color="info" />;
    } else if (activeHook.processingState === ProcessingState.Running) {
      return <IndeterminateCheckBoxIcon color="primary" />;
    } else if (activeHook.processingState === ProcessingState.Successful) {
      return <CheckBoxIcon color="success" />;
    } else if (activeHook.processingState === ProcessingState.Error) {
      return <ErrorIcon color="error" />;
    } else {
      return <DangerousIcon color="warning" />; // should never happen
    }
  }

  const hasMessage = activeHook.processingState === ProcessingState.Error

  const showMessage = () => {
    alert(activeHook.errorMessage);
  }

  return (
    <ListItem>
      <ListItemIcon>{getIcon()}</ListItemIcon>
      <ListItemText primary={title} secondary={
        hasMessage
          ? <Button href="#" variant="text"
	       onClick={() => showMessage()}>Show Error</Button>
          : null
      } />
    </ListItem>
  );
}

export default CsvRow;
