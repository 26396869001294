import { ChangeEvent, useState, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import CsvDialog from 'components/Misc/CsvDialog';
import Information from 'components/Misc/Information';
import { RadioIngestData } from 'types/RadioIngestData';
import { CsvDialogActions } from 'types/CsvDialogActions';
import useProvisionIngestionJob from 'hooks/useProvisionIngestionJob';
import { CLUSTER_PROD3, CLUSTER_PROD5, CLUSTER_ATTRIBUTE } from 'constants/cluster';

// You can get a list using the `clusters` query with `type: RT`
const DEFAULT_THUMBNAIL = 'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/mwslmjdoqqyke5afhper';

const BulkIngestionJobProvisioning = () => {
  const [showCsvDialog, setShowCsvDialog] = useState<boolean>(false);

  const paragraphs = [
    'Upload a CSV containing Ingestion Job information for Radio stations',
    'CSV requires the following columns:',
    '...',
    'source_name, schedule_name, schedule_program_format, scheduledDay, startTime, stopTime',
    '---',
    'Jobs are provisioned on PRD3 by default. ',
    'You can add an optional `cluster_id` column to the CSV, and use  `prd3` for most jobs (default), `attribute` for attribute jobs,and `prd5` to run adhoc jobs'
  ]; 

  const getThumbnailUrl = (data: any): string => {
    if (!data.thumbnail_url || data.thumbnail_url.trim().length < 10) {
      return DEFAULT_THUMBNAIL;
    }
    return data.thumbnail_url.trim();
  }

  const getClusterId = (data: any): string => {
    if (!data.cluster_id) {
      return CLUSTER_PROD3;
    }
    switch (data.cluster_id.trim().toLowerCase()) {
      case 'prd5':
	return CLUSTER_PROD5;
      case 'attribute':
	return CLUSTER_ATTRIBUTE;
      default:
	return CLUSTER_PROD3;
    }
  }

  const parseRow = (data: any): RadioIngestData|null => {
   
    if (!data.schedule_name
	|| !data.scheduledDay
	|| !data.source_name
	|| !data.startTime
	|| !data.stopTime) {
      return null;
    }

    let programFormat = "Other";
    if (!data.schedule_program_format
	|| data.schedule_program_format.trim() === '') {;
      programFormat = "Other";
    }
	
    return {
      clusterId: getClusterId(data),
      scheduleName: data.schedule_name.trim(),
      scheduleProgramFormat: programFormat,
      scheduledDay: data.scheduledDay.trim(),
      sourceName: data.source_name.trim(),
      startTime: data.startTime.trim(),
      stopTime: data.stopTime.trim(),
      thumbnailUrl: getThumbnailUrl(data),
    };
  }

  const dialogRef = useRef<CsvDialogActions>(null);

  const closeCsvDialog = () => {
    setShowCsvDialog(false);
  }

  const uploadCsv = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files) {
      alert(`No uploaded file`);
      throw new Error(`No uploaded file`);
    }

    if (!dialogRef || !dialogRef.current) {
      throw new Error("Dialog component broken");
    }
    dialogRef.current.processFile(e.target.files[0]);
    setShowCsvDialog(true);
    e.currentTarget.value = '';
  };

  return (
    <Box>
      <Stack direction="row">
	<h3>Radio: Provision <em>Ingestion Jobs</em> in Bulk</h3>
	<Information
	  title="Create Ingestion Jobs"
	  paragraphs={paragraphs}
	/>
      </Stack>

      <input
        type="file" name="csvFile"
        onChange={(e) => uploadCsv(e)}
      />

      <CsvDialog
        ref={dialogRef}
	title="Ingestion Job Csv Summary"
	open={showCsvDialog}
	handleClose={closeCsvDialog}
	rowParser={parseRow}
	rowProcessor={useProvisionIngestionJob}
	titleField='scheduleName'
      />
    </Box>
  );
}

export default BulkIngestionJobProvisioning;
