import { InMemoryCache, makeVar } from '@apollo/client';
import { User } from '../types/User';
import { Environments } from '../utils/envs';

export const isAuthenticatedVar = makeVar<boolean>(false);
export const authTokenVar = makeVar<string>(``);
export const environmentVar = makeVar<Environments>(Environments.US_STAGE);
export const userVar = makeVar<User | undefined>(undefined)

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isAuthenticated: {
          // TODO: make this dynamic based on auth token query from aiWARE
          read: () => true,
        },
      },
    },
  }
});
