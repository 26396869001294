import { Environments } from './envs'

const getVeritoneEnv = (env: Environments): string => {
  switch (env) {
    case Environments.US_PRODUCTION:
      return `https://api.us-1.veritone.com/v3/graphql`
    case Environments.US_STAGE:
      return `https://api.stage.us-1.veritone.com/v3/graphql`
    case Environments.UK_PRODUCTION:
      return `https://api.uk-1.veritone.com/v3/graphql`
    case Environments.UK_STAGE:
      return `https://api.stage.uk-1.veritone.com/v3/graphql`
    case Environments.DEV:
      return `https://api.dev.us-1.veritone.com/v3/graphql`
  }

}

export default getVeritoneEnv
