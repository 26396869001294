import MainHeader from "../../components/MainHeader";
import { Box, Card, CardContent, Typography } from '@mui/material';

export default function Navigate () {

  const ideas = [
  ].map(idea => (
    <Card>
      <CardContent>
	<h3>{idea[0]}</h3>
	<p>{idea[1]}</p>
      </CardContent>
    </Card>
  ))

  const sections = [
  ].map(section => (
    <Card>
      <CardContent>
        {section}
      </CardContent>
    </Card>
  ))

  return (
    <Box>
      <MainHeader title="Navigate" />
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>{sections}</Box>
      <h2>Roadmap</h2>
      <Typography>Coming soon...</Typography>
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>{ideas}</Box>
    </Box>
  );
}
