import { Typography } from '@mui/material';

interface iMainHeader {
  title: String
}
const MainHeader = (props:iMainHeader) => {
  return (
    <Typography variant="h5">
      {props.title}
    </Typography>
  )
}

export default MainHeader
