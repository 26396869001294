import MainHeader from "../../components/MainHeader";
import { Box, Card, CardContent, Typography } from '@mui/material';
//import StationCountPerOrg from 'components/Attribute/StationCountPerOrg';

export default function Aiware() {

  const ideas = [
    [`Reprocess Campaign`, `Reprocess attribution data for single campaign`],
    [`Delete Advertiser`, `VTN-44446: Delete an advertiser from the system`],
    [`Delete uploaded logs`, `Delete uploaded Spot logs for an organization`],
    [`Duplicate Premiere logs`, `Check for Premiere logs duplicated in a campaign`],
    [`Compare Campaigns`, `VTN-44557: Compare headline data from 2 different campaigns`],
  ].map(idea => (
    <Card key={idea[0]}>
      <CardContent>
	<h3>{idea[0]}</h3>
	<p>{idea[1]}</p>
      </CardContent>
    </Card>
  ))

  return (
    <Box>
      <MainHeader title="Attribute" />
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>
      {/*        <StationCountPerOrg /> */}
      </Box>
      <h2>Roadmap</h2>
      <Typography>Coming soon...</Typography>
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>{ideas}</Box>
    </Box>
  );
}
