import { environmentVar } from '../apollo/cache'
import { Environments } from './envs'

const getEnvName = (): string => {
  const env = environmentVar()
  switch (env) {
    case Environments.US_PRODUCTION:
      return `US Production`
    case Environments.UK_PRODUCTION:
      return `UK Production`
    case Environments.US_STAGE:
      return `US Stage`
    case Environments.UK_STAGE:
      return `UK Stage`
    case Environments.DEV:
      return `Dev`
  }

}

export default getEnvName
