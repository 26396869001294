import MainHeader from "components/MainHeader";
import { Box } from '@mui/material';
import BulkIntegration from 'components/WideOrbit/BulkIntegration';

export default function Dmh() {

  return (
    <Box>
      <MainHeader title="Wide Orbit Integration" />
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>
        <BulkIntegration />
      </Box>
    </Box>
  );
}
