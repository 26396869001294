//This component represents a full CsvFile. It is where CsvRow components are pushed to, so the user has a clearly demarcated area to see the results of processing a Csv file.

import { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List} from "@mui/material";
import Papa from 'papaparse';
import CsvRow from 'components/Misc/CsvRow';
import { CsvDialogProps } from 'types/CsvDialogProps';
import { ProcessingState } from 'types/ProcessingState';
import { ProcessorHook } from 'types/ProcessorHook';

const CsvDialog = forwardRef(({
  title,
  open,
  handleClose,
  rowParser,
  rowProcessor,
  titleField,
}: CsvDialogProps, ref) => {

  const [csvRows, setCsvRows] = useState<any[]>([]);
  const [userData, setUserData] = useState<any|null>(null);

  useImperativeHandle(ref, () => ({
    updateData(d: object) {
      setUserData(d);
    },
    processFile(f: File) {
      Papa.parse(f, {
	header: true,
	skipEmptyLines: 'greedy', // skip empty lines (and lines w/o content)
	complete(results) {
	  if (!results.data) {
	    throw new Error("Error parsing CSV file");
	  }
	  if (results.data.length < 1) {
	    throw new Error("Empty CSV file");
	  }

	  const rows = results.data.map(rowParser)

	  setCsvRows(rows)
	},
	error(err) {
	  throw new Error(`Unable to parse CSV file ${err}`)
	}
      });
    }
  }));

  const dummyHook = ():ProcessorHook => {
    const processor = async (_data: any) => {} // do nothing
    const processingState = ProcessingState.Error;
    const errorMessage = "Malformed Row";
    const printLogs = () => {
      console.log("Malformed Row");
    }
    return {
      processor,
      processingState,
      errorMessage,
      printLogs
    }
  }

  const rows = csvRows.map((row, i) => {
    const t = (!row) ? "Malformed Row" : row[titleField];
    const d = (!row) ? null : row;
    const h = (!row) ? dummyHook : rowProcessor;
    return (
      <CsvRow key={i}
      title={t}
	data={d}
	userData={userData}
	hook={h}
	/>
      );
  })

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="csv-processing"
        aria-describedby="csv-processing-details"
      >
        <DialogTitle id="Csv-processing-dialog-title">
	  {title}
        </DialogTitle>
        <DialogContent>
	  <List>
	    {rows}
	  </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
  )
})

export default CsvDialog;
