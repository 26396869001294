import { ChangeEvent, useState, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import CsvDialog from 'components/Misc/CsvDialog';
import Information from 'components/Misc/Information';
import { RadioSourceData } from 'types/RadioSourceData';
import { CsvDialogActions } from 'types/CsvDialogActions';
import useProvisionSource from 'hooks/useProvisionSource';

const DEFAULT_THUMBNAIL = 'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/mwslmjdoqqyke5afhper';

const BulkSourceProvisioning = () => {

  const [showCsvDialog, setShowCsvDialog] = useState<boolean>(false);

  const paragraphs = [
    'Upload a CSV containing Source information for Radio stations',
    'CSV requires the following columns:',
    '...',
    'source_name, site_url, format, tz, station, stream_url, call_sign, band, parent_org',
    '---',
    'You can optionally add a `thumbnail_url` column with the url to the image to display in Veritone apps (like Discovery)'
  ]; 

  const getThumbnailUrl = (data: any): string => {
    if (!data.thumbnail_url || data.thumbnail_url.trim().length < 10) {
      return DEFAULT_THUMBNAIL;
    }
    return data.thumbnail_url.trim();
  }

  const parseRow = (data: any): RadioSourceData|null => {
   
    if (!data.band
	|| !data.call_sign
	|| !data.format
	|| !data.parent_org
	|| !data.site_url
	|| !data.source_name
	|| !data.station
	|| !data.stream_url
	|| !data.tz) {
      return null;
    }
    return {
      band: data.band.trim(),
      callSign: data.call_sign.trim(),
      format: data.format.trim(),
      parentOrg: data.parent_org.trim(),
      siteUrl: data.site_url.trim(),
      sourceName: data.source_name.trim(),
      station: data.station.trim(),
      streamUrl: data.stream_url.trim(),
      thumbnailUrl: getThumbnailUrl(data),
      tz: data.tz.trim(),
    };
  }

  const dialogRef = useRef<CsvDialogActions>(null);

  const closeCsvDialog = () => {
    setShowCsvDialog(false);
  }

  const uploadCsv = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files) {
      alert(`No uploaded file`);
      throw new Error(`No uploaded file`);
    }

    if (!dialogRef || !dialogRef.current) {
      throw new Error("Dialog component broken");
    }
    dialogRef.current.processFile(e.target.files[0]);
    setShowCsvDialog(true);
    e.currentTarget.value = '';
  };

  return (
    <Box>
      <Stack direction="row">
	<h3>Radio: Provision <em>Sources</em> in Bulk</h3>
	<Information
	  title="Create Sources"
	  paragraphs={paragraphs}
	/>
      </Stack>

      <input
        type="file" name="csvFile"
        onChange={(e) => uploadCsv(e)}
      />
      <CsvDialog
        ref={dialogRef}
        title="Source Csv Summary"
	open={showCsvDialog}
	handleClose={closeCsvDialog}
	rowParser={parseRow}
	rowProcessor={useProvisionSource}
	titleField='sourceName'
      />
    </Box>
  );
}

export default BulkSourceProvisioning;
