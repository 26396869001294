import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import useWatchlistProgramCsv from '../../hooks/useWatchlistProgramCsv';
//import { DataGrid, GridColDef } from '@mui/x-data-grid';

//const columns: GridColDef[] = [
//  { field: 'id', headerName: 'Watchlist Id', width: 150 },
//  { field: 'programId', headerName: 'Program Id', width: 150 },
//  { field: 'status', headerName: 'Status', width: 150 },
//];

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function AddProgramToWatchlist() {
  const {
    uploadCsv: handleFileUpload,
    progress,
    showProgress,
    watchlistPrograms,
    processWatchlistPrograms,
  } = useWatchlistProgramCsv(true);

  return (
    <div>
      <h3>Add ProgramID to Watchlist</h3>
      <p>Upload a CSV, containing the `WatchlistID` and `ProgramID`, and this tool will add the programID to the watchlist. (Sample CSV here TODO)</p>
      <input
        type="file" name="csvFile"
        onChange={handleFileUpload}
      />
      <Button
        variant="contained"
        disabled={!watchlistPrograms.length}
        onClick={processWatchlistPrograms}
      >
        Update Watchlists
      </Button>
      {showProgress ?
        <div><b>Processing Progress</b>:
          <LinearProgressWithLabel variant="determinate" value={progress} /></div>
        : null
      }
    </div>
  );
}
