import MainHeader from "../../components/MainHeader";
import AddProgramToWatchlist from "./AddProgramToWatchlist";
import RemoveProgramFromWatchlist from "./RemoveProgramFromWatchlist";
import DuplicateChecker from "./DuplicateChecker";
import { Box, Card, CardContent, Typography } from '@mui/material';

export default function Discovery() {
  const ideas = [
    [`Recorrelate a watchlist`, `Fast way to recorrelate a specific watchlist`],
    [`Spots vs Audio`, `For a watchlist, show spots vs discovered audio (SDO vs TDO)`],
    [`Monthly Recap`,`MNE-1682: Enter watchlist, select month, and show recap numbers.`],
  ].map(idea => (
    <Card key={idea[0]}>
      <CardContent>
	<h3>{idea[0]}</h3>
	<p>{idea[1]}</p>
      </CardContent>
    </Card>
  ))

  const sections = [
    <AddProgramToWatchlist />,
    <DuplicateChecker />,
    <RemoveProgramFromWatchlist />,
  ].map(section => (
    <Card>
      <CardContent>
        {section}
      </CardContent>
    </Card>
  ))

  return (
    <Box>
      <MainHeader title="Discovery" />
      <Box sx={{
        display: `grid`,
        gap: 2,
      }}>{sections}</Box>
      <h2>Roadmap</h2>
      <Typography>Coming soon...</Typography>
      <Box sx={{
        display: `grid`,
        gap: 2,
	
      }}>{ideas}</Box>
    </Box>
  );
}
