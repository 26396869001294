import { useState } from 'react';
import {
  CreateRadioSourceDocument, GetRadioSourceDocument, UpdateRadioSourceDocument,
} from 'graphql/generated';
import { RadioSourceData } from 'types/RadioSourceData';
import { ProcessingState } from 'types/ProcessingState';
import { ProcessorHook } from 'types/ProcessorHook';
import client from 'apollo/client';
import getCollaborators from 'components/Aiware/BulkSourceProvisioning/getCollaborators';
import useConsoleLogs from './useConsoleLogs';

const SOURCE_RADIO = "1"; // Hardcoded CE Radio use cases (iHM, Alpha, Audacy)

const useProvisionSource = ():ProcessorHook => {
  const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.NotStarted);
  const [errorMessage, setErrorMessage] = useState<string>(``);

  const {
    formattedLogs,
    addLog,
  } = useConsoleLogs()

  const finishProcessing = (msg: string) => {
    if (msg === ``) {
      // successful
      setProcessingState(ProcessingState.Successful);
      addLog("Success!");
    } else {
      // Error
      addLog(msg);
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
      throw new Error(msg);
    }
  }

  const sendCreateSourceRequest = async (csvRow: RadioSourceData) => {
    const sourceTypeId = SOURCE_RADIO;
    const {
      band,
      callSign,
      format,
      siteUrl,
      sourceName,
      station,
      streamUrl,
      thumbnailUrl,
      tz,
    } = csvRow
    const collaborators = getCollaborators(csvRow);
    const isPublic: boolean = false;

    addLog(`Creating new source`);

    const {
      data,
    } = await client.mutate({
      mutation: CreateRadioSourceDocument,
      variables: {
        band,
        callSign,
        collaborators,
        format,
        isPublic,
        siteUrl,
        sourceName,
        sourceTypeId,
        station,
        streamUrl,
        thumbnailUrl,
        tz,
      },
    })

    const msg: string = (!data || !data.createSource)
      ? `Error creating source for ${csvRow.sourceName}`
      : '';
    finishProcessing(msg);
  }

  const sendUpdateSourceRequest = async (sourceId: string, csvRow: RadioSourceData) => {
    const {
      band,
      callSign,
      format,
      siteUrl,
      sourceName,
      station,
      streamUrl,
      thumbnailUrl,
      tz,
    } = csvRow
    const collaborators = getCollaborators(csvRow);
    const isPublic: boolean = false;

    addLog(`Updating source: ${sourceName} (ID: ${sourceId})`);

    const {
      data,
    } = await client.mutate({
      mutation: UpdateRadioSourceDocument,
      variables: {
        band,
        callSign,
        collaborators,
        format,
        isPublic,
        siteUrl,
        sourceId,
        sourceName,
        station,
        streamUrl,
        thumbnailUrl,
        tz,
      },
    })

    const msg = (!data || !data!.updateSource)
      ? `Error updating source for ${sourceName}`
      : '';
    finishProcessing(msg);
  }

  // See if the source exists, and if it does, return the sourceId
  const sendGetSourceRequest = async (csvRow: RadioSourceData) => {
    addLog(`Sending GetSourceRequest`);

    const sourceName = csvRow.sourceName;
    const {
      data,
    } = await client.query({
      query: GetRadioSourceDocument,
      variables: {
        sourceName,
      },
    })

    if (!data || !data.sources) {
      finishProcessing(`Error searching for source ${sourceName}`);
    }
    if (data.sources.count < 1) {
      await sendCreateSourceRequest(csvRow);
    } else {
      const sourceId = data.sources.records[0].id;
      await sendUpdateSourceRequest(sourceId, csvRow);
    }
  }

  const processor = async (data: RadioSourceData) => {
    addLog(`[${data.sourceName}] Starting Source Provisioning`);
    try {
      setProcessingState(ProcessingState.Running);
      await sendGetSourceRequest(data);
    } catch(err: unknown) {
      const msg = (err instanceof Error)
	? err.message
	: "Unknown error (ID: 777)";
      setErrorMessage(msg);
      setProcessingState(ProcessingState.Error);
    }
  }

  const printLogs = () => {
    console.log(formattedLogs);
  }

  return {
    processor,
    processingState,
    errorMessage,
    printLogs
  }
}

export default useProvisionSource;
