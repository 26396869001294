import { useLazyQuery } from '@apollo/client';
import { GetMentionsInWatchlistDocument } from '../graphql/generated';
import { Mention } from '../types/Mention';

const STATUS_INVALID: number = 5;
const DUPLICATE_WINDOW: number = 2; // 2 minutes

interface IDuplicateResults {
  totalMentions: number,
  totalDuplicates: number,
}

const useDuplicateMentions = () => {
  const [getMentions] = useLazyQuery(GetMentionsInWatchlistDocument);

  const getAllMentions = async function(watchlistId: number) {
    let offset: number = 0;
    let limit: number = 500;
    let resultSize: number = limit;
    let allMentions: Mention[] = []
    while (resultSize >= limit) {
      try {
	// keep getting mentions, until we have all mentions for the watchlist
	const {
	  error,
	  data,
	} = await getMentions({ variables: {
	  watchlistId: watchlistId.toString(),
	  offset: offset,
	  limit: limit
	} });
	if (!data || !data.mentions || !data.mentions.count
	    || !data.mentions.records) {
	  console.error(`No result when getting mentions: ${error}`);
	  return allMentions;
	}
	resultSize = data.mentions.count;
	offset += limit;
	allMentions = allMentions.concat(data.mentions.records.map(m => {
	  return {
	    id: parseInt(m.id, 10),
	    statusId: m.statusId ? parseInt(m.statusId, 10) : undefined,
	    mentionDate: m.mentionDate ? new Date(m.mentionDate) : undefined,
	    metadata: m.metadata || {
	      ...m.metadata,
	      veritoneProgram: {
		programId: undefined
	      }
	    }
	  }
	}));
      } catch (error) {
	alert(`Error while getting mentions for ${watchlistId}: Error ${error}`)
	throw new Error(`Error while getting mentions for ${watchlistId}: Error ${error}`)
      }
    }
    return allMentions;
  }

  const calculateDuplicates = function(mentions: Mention[]) {
    if (mentions.length < 1) {
      return {
	totalMentions: 0,
	totalDuplicates: 0
	}
    };
    let prevRecord: Mention = mentions[0];
    let duplicateMentions: Mention[] = [];
    // go through all mentions, to extract duplicates
    mentions.forEach((m: Mention) => {
      // hacky. Lets make sure all fields exist
      if (!prevRecord.mentionDate || !m.mentionDate
	  || !m.metadata.veritoneProgram
          || !m.metadata.veritoneProgram.programId
          || !prevRecord.metadata.veritoneProgram
	  || !prevRecord.metadata.veritoneProgram.programId) {
	// Missing fields, so don't continue processing
	prevRecord = m;
	return;
      }
      const thresholdDate= new Date(
	prevRecord.mentionDate.getTime() +
	  DUPLICATE_WINDOW*60000);
      if (!m.metadata.veritoneProgram ||
          !m.metadata.veritoneProgram.programId ||
          !prevRecord.metadata.veritoneProgram ||
	  !prevRecord.metadata.veritoneProgram.programId) {
	// mention w/o program can't be a duplicate
      } else if (m.metadata.veritoneProgram.programId !==
	  prevRecord.metadata.veritoneProgram.programId) {
	// duplicates must be on the same program
      } else if (thresholdDate < m.mentionDate) {
	// spot times are not overlapping
      } else if (m.statusId !== STATUS_INVALID) {
	duplicateMentions.push(m);
      }
      prevRecord = m;
    });

    const results: IDuplicateResults = {
      totalMentions: mentions.length,
      totalDuplicates: duplicateMentions.length
    }
    return results;
  }

  const getDuplicates = async function (watchlistId: number) {
    console.log("Finding mentions in watchlist ID: " + watchlistId);
    const allMentions = await getAllMentions(watchlistId);
    return calculateDuplicates(allMentions);
  }

  return {
    getDuplicates, 
  };
};

export default useDuplicateMentions;
