import { ChangeEvent, useState, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import CsvDialog from 'components/Misc/CsvDialog';
import Information from 'components/Misc/Information';
import { RadioDagData } from 'types/RadioDagData';
import { CsvDialogActions } from 'types/CsvDialogActions';
import useProvisionOrgForRadio from 'hooks/useProvisionOrgForRadio';

const BulkOrgProvisioningForRadio = () => {
  const [showCsvDialog, setShowCsvDialog] = useState<boolean>(false);

  const paragraphs = [
    'Registers the English Speechmatics V9 DAG, along with Attribute Engines',
    'Must be run as superadmin',
    'CSV requires the following columns:',
    '...',
    'org_id'
  ]; 

  const parseRow = (data: any): RadioDagData|null => {
   
    if (!data.org_id) {
      return null;
    }
    return {
      orgId: data.org_id.trim(),
    };
  }

  const dialogRef = useRef<CsvDialogActions>(null);

  const closeCsvDialog = () => {
    setShowCsvDialog(false);
  }

  const uploadCsv = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e || !e.target || !e.target.files) {
      alert(`No uploaded file`);
      throw new Error(`No uploaded file`);
    }

    if (!dialogRef || !dialogRef.current) {
      throw new Error("Dialog component broken");
    }
    dialogRef.current.processFile(e.target.files[0]);
    setShowCsvDialog(true);
    e.currentTarget.value = '';
  };


  return (
    <Box>
      <Stack direction="row">
	<h3>Radio: Provision <em>Orgs</em> for Radio</h3>
	<Information
	  title="Provision Org"
	  paragraphs={paragraphs}
	/>
      </Stack>

      <input
        type="file" name="csvFile"
        onChange={(e) => uploadCsv(e)}
      />

      <CsvDialog
        ref={dialogRef}
	title="Org Provisioning Csv Summary"
	open={showCsvDialog}
	handleClose={closeCsvDialog}
	rowParser={parseRow}
	rowProcessor={useProvisionOrgForRadio}
	titleField='orgId'
      />
    </Box>
  );
}

export default BulkOrgProvisioningForRadio;
