import { useState } from 'react';

type ConsoleLog = string

type AddLogFunc = (message: string) => void

type ReturnPayload = {
  logs: ConsoleLog[]
  formattedLogs: string
  addLog: AddLogFunc
}

const useConsoleLogs = (): ReturnPayload => {
  const [logs, setLogs] = useState<ConsoleLog[]>([]);

  const addLog: AddLogFunc = (message) => {
    // TODO: Not working. Only saves the last log record, not the whole batch
    setLogs([
      ...logs,
      message,
    ])
  }

  return {
    logs,
    formattedLogs: logs.join(`\n`),
    addLog,
  };
};

export default useConsoleLogs;
